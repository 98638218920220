import React from "react";
import Field from "./field.js";
import mimeTypes from "./mimes.js";

const extensionsToMimes = (exts) => {
  const output = [];
  if (!exts) {
    return "";
  }
  exts = exts
    .split(/[, ]/g)
    .filter(e => e.length)
    .map(e => e.trim().replace(/^\./g, ""));
  output.push(...exts);
  exts.forEach(e => {
    const foundMime = mimeTypes.find(mime => mime.extensions.includes(e));
    if (!foundMime) {
      return;
    }
    output.push(...foundMime.extensions, ...foundMime.mimes);
  });
  return [...new Set(output)].join(",");
}

const Fileupload = (props) => {
  const validationFunction = (value) => {
    const files = [...(value.files || [])];
    const maxSize = (props.maxFileSize || 32)*1024*1024;
    // tester la taille
    if (files.reduce((a,b) => {
      return (a.size || a) + (b.size || b)
    }, 0) > maxSize) {
      console.error("max file size exceeded");
      return false;
    }
    // tester le nombre
    if (files.length < 1) {
      console.error("one file at least is mandatory");
      return false;
    }
    if (props.maxFiles && files.length > parseInt(props.maxFiles)) {
      console.error("max files number exceeded");
      return false;
    }
    // tester les extensions
    if (props.allowedExtensions) {
      const mimes = extensionsToMimes(props.allowedExtensions);
      if (!files.every(f => mimes.includes(f.type))) {
        console.error("unallowed file type");
        return false;
      }
    }
    return true;
  };
  const description = (() => {
    const maxSize = (props.maxFileSize !== "") && (props.maxFileSize+"Mo") || "32Mo";
    const maxFiles = props.maxFiles;
    const extensions = (props.allowedExtensions)
      ? props.allowedExtensions.split(/[, ]/g)
          .filter(e => e.length)
          .map(e => e.trim().replace(/^\./g, ""))
          .join(", ")
      : false;
    const output = [`Taille max. des fichiers : ${maxSize}`];
    if (extensions) {
      output.push(`Type de fichiers acceptés : ${extensions}`);
    }
    if (maxFiles) {
      output.push(`Nombre maximum de fichiers : ${maxFiles}`);
    }
    return (
      <>
        <p className="upload-title">
          Téléversez le fichier ou cliquez ici
        </p>
        <p className="upload-size">
          { `Taille max. ${maxSize}` }
        </p>
      </>
    )
  })();

  const Wrapper = ({ children }) => {
    return (
      <div className="upload-wrapper">
        {description}
        {children}
      </div>
    )
  };

  return (<Field
    {...props}
    wrapper={ Wrapper }
    accept={ extensionsToMimes(props.allowedExtensions) }
    type="file"
    validationFunction={ validationFunction }
  />);
};

export default Fileupload;
