const mimeTypes = [
  {
    "extensions": [
      "aac"
    ],
    "mimes": [
      "audio/aac"
    ]
  },
  {
    "extensions": [
      "abw"
    ],
    "mimes": [
      "application/x-abiword"
    ]
  },
  {
    "extensions": [
      "arc"
    ],
    "mimes": [
      "application/x-freearc"
    ]
  },
  {
    "extensions": [
      "avif"
    ],
    "mimes": [
      "image/avif"
    ]
  },
  {
    "extensions": [
      "avi"
    ],
    "mimes": [
      "video/x-msvideo"
    ]
  },
  {
    "extensions": [
      "azw"
    ],
    "mimes": [
      "application/vnd.amazon.ebook"
    ]
  },
  {
    "extensions": [
      "bin"
    ],
    "mimes": [
      "application/octet-stream"
    ]
  },
  {
    "extensions": [
      "bmp"
    ],
    "mimes": [
      "image/bmp"
    ]
  },
  {
    "extensions": [
      "bz"
    ],
    "mimes": [
      "application/x-bzip"
    ]
  },
  {
    "extensions": [
      "bz2"
    ],
    "mimes": [
      "application/x-bzip2"
    ]
  },
  {
    "extensions": [
      "cda"
    ],
    "mimes": [
      "application/x-cdf"
    ]
  },
  {
    "extensions": [
      "csh"
    ],
    "mimes": [
      "application/x-csh"
    ]
  },
  {
    "extensions": [
      "css"
    ],
    "mimes": [
      "text/css"
    ]
  },
  {
    "extensions": [
      "csv"
    ],
    "mimes": [
      "text/csv"
    ]
  },
  {
    "extensions": [
      "doc"
    ],
    "mimes": [
      "application/msword"
    ]
  },
  {
    "extensions": [
      "docx"
    ],
    "mimes": [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ]
  },
  {
    "extensions": [
      "eot"
    ],
    "mimes": [
      "application/vnd.ms-fontobject"
    ]
  },
  {
    "extensions": [
      "epub"
    ],
    "mimes": [
      "application/epub+zip"
    ]
  },
  {
    "extensions": [
      "gz"
    ],
    "mimes": [
      "application/gzip"
    ]
  },
  {
    "extensions": [
      "gif"
    ],
    "mimes": [
      "image/gif"
    ]
  },
  {
    "extensions": [
      "htm .html"
    ],
    "mimes": [
      "text/html"
    ]
  },
  {
    "extensions": [
      "ico"
    ],
    "mimes": [
      "image/vnd.microsoft.icon"
    ]
  },
  {
    "extensions": [
      "ics"
    ],
    "mimes": [
      "text/calendar"
    ]
  },
  {
    "extensions": [
      "jar"
    ],
    "mimes": [
      "application/java-archive"
    ]
  },
  {
    "extensions": [
      "jpeg",
      "jpg"
    ],
    "mimes": [
      "image/jpeg"
    ]
  },
  {
    "extensions": [
      "js"
    ],
    "mimes": [
      "text/javascript"
    ]
  },
  {
    "extensions": [
      "json"
    ],
    "mimes": [
      "application/json"
    ]
  },
  {
    "extensions": [
      "jsonld"
    ],
    "mimes": [
      "application/ld+json"
    ]
  },
  {
    "extensions": [
      "mid",
      "midi"
    ],
    "mimes": [
      "audio/midi",
      "audio/x-midi"
    ]
  },
  {
    "extensions": [
      "mjs"
    ],
    "mimes": [
      "text/javascript"
    ]
  },
  {
    "extensions": [
      "mp3"
    ],
    "mimes": [
      "audio/mpeg"
    ]
  },
  {
    "extensions": [
      "mp4"
    ],
    "mimes": [
      "video/mp4"
    ]
  },
  {
    "extensions": [
      "mpeg"
    ],
    "mimes": [
      "video/mpeg"
    ]
  },
  {
    "extensions": [
      "mpkg"
    ],
    "mimes": [
      "application/vnd.apple.installer+xml"
    ]
  },
  {
    "extensions": [
      "odp"
    ],
    "mimes": [
      "application/vnd.oasis.opendocument.presentation"
    ]
  },
  {
    "extensions": [
      "ods"
    ],
    "mimes": [
      "application/vnd.oasis.opendocument.spreadsheet"
    ]
  },
  {
    "extensions": [
      "odt"
    ],
    "mimes": [
      "application/vnd.oasis.opendocument.text"
    ]
  },
  {
    "extensions": [
      "oga"
    ],
    "mimes": [
      "audio/ogg"
    ]
  },
  {
    "extensions": [
      "ogv"
    ],
    "mimes": [
      "video/ogg"
    ]
  },
  {
    "extensions": [
      "ogx"
    ],
    "mimes": [
      "application/ogg"
    ]
  },
  {
    "extensions": [
      "opus"
    ],
    "mimes": [
      "audio/opus"
    ]
  },
  {
    "extensions": [
      "otf"
    ],
    "mimes": [
      "font/otf"
    ]
  },
  {
    "extensions": [
      "png"
    ],
    "mimes": [
      "image/png"
    ]
  },
  {
    "extensions": [
      "pdf"
    ],
    "mimes": [
      "application/pdf"
    ]
  },
  {
    "extensions": [
      "php"
    ],
    "mimes": [
      "application/x-httpd-php"
    ]
  },
  {
    "extensions": [
      "ppt"
    ],
    "mimes": [
      "application/vnd.ms-powerpoint"
    ]
  },
  {
    "extensions": [
      "pptx"
    ],
    "mimes": [
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ]
  },
  {
    "extensions": [
      "rar"
    ],
    "mimes": [
      "application/vnd.rar"
    ]
  },
  {
    "extensions": [
      "rtf"
    ],
    "mimes": [
      "application/rtf"
    ]
  },
  {
    "extensions": [
      "sh"
    ],
    "mimes": [
      "application/x-sh"
    ]
  },
  {
    "extensions": [
      "svg"
    ],
    "mimes": [
      "image/svg+xml"
    ]
  },
  {
    "extensions": [
      "swf"
    ],
    "mimes": [
      "application/x-shockwave-flash"
    ]
  },
  {
    "extensions": [
      "tar"
    ],
    "mimes": [
      "application/x-tar"
    ]
  },
  {
    "extensions": [
      "tif .tiff"
    ],
    "mimes": [
      "image/tiff"
    ]
  },
  {
    "extensions": [
      "ts"
    ],
    "mimes": [
      "video/mp2t"
    ]
  },
  {
    "extensions": [
      "ttf"
    ],
    "mimes": [
      "font/ttf"
    ]
  },
  {
    "extensions": [
      "txt"
    ],
    "mimes": [
      "text/plain"
    ]
  },
  {
    "extensions": [
      "vsd"
    ],
    "mimes": [
      "application/vnd.visio"
    ]
  },
  {
    "extensions": [
      "wav"
    ],
    "mimes": [
      "audio/wav"
    ]
  },
  {
    "extensions": [
      "weba"
    ],
    "mimes": [
      "audio/webm"
    ]
  },
  {
    "extensions": [
      "webm"
    ],
    "mimes": [
      "video/webm"
    ]
  },
  {
    "extensions": [
      "webp"
    ],
    "mimes": [
      "image/webp"
    ]
  },
  {
    "extensions": [
      "woff"
    ],
    "mimes": [
      "font/woff"
    ]
  },
  {
    "extensions": [
      "woff2"
    ],
    "mimes": [
      "font/woff2"
    ]
  },
  {
    "extensions": [
      "xhtml"
    ],
    "mimes": [
      "application/xhtml+xml"
    ]
  },
  {
    "extensions": [
      "xls"
    ],
    "mimes": [
      "application/vnd.ms-excel"
    ]
  },
  {
    "extensions": [
      "xlsx"
    ],
    "mimes": [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ]
  },
  {
    "extensions": [
      "xml"
    ],
    "mimes": [
      "application/xml",
      "text/xml",
      ".xml",
      "application/atom+xml",
      "application/xml"
    ]
  },
  {
    "extensions": [
      "xul"
    ],
    "mimes": [
      "application/vnd.mozilla.xul+xml"
    ]
  },
  {
    "extensions": [
      "zip"
    ],
    "mimes": [
      "application/zip"
    ]
  },
  {
    "extensions": [
      "3gp"
    ],
    "mimes": [
      "video/3gpp",
      "audio/3gpp"
    ]
  },
  {
    "extensions": [
      "3g2"
    ],
    "mimes": [
      "video/3gpp2",
      "audio/3gpp2"
    ]
  },
  {
    "extensions": [
      "7z"
    ],
    "mimes": [
      "application/x-7z-compressed"
    ]
  }
];
export default mimeTypes;
